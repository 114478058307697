export const filterType = (keyword, arr) => {
    switch (keyword) {
        case 'have':
            return [...arr].filter((el) => el.manager_id);
        case 'false':
            return [...arr].filter((el) => !el.manager_id);
        default:
            return arr;
    }
};
export const filterData = (keyword, properties, arr) => {
    const result = arr.filter((el) => {
        if (properties.includes('email')) {
            return el.email && el.email.toLowerCase().includes(keyword.toLowerCase());
        } else if (properties.includes('site')) {
            return el.site && el.site.toLowerCase().includes(keyword.toLowerCase());
        } else if (properties.includes('name')) {
            return (
                (el.first_name && el.first_name.toLowerCase().startsWith(keyword.toLowerCase())) ||
                (el.second_name && el.second_name.toLowerCase().startsWith(keyword.toLowerCase())) ||
                (`${el.first_name} ${el.second_name}`.toLowerCase().includes(keyword.toLowerCase())) ||
                (`${el.second_name} ${el.first_name}`.toLowerCase().includes(keyword.toLowerCase()))
            );
        } else {
            return properties.some(property => el[property] && el[property].includes(keyword));
        }
    });
    return result;
};
export const filterName = (keyword, arr) => {
    const result = arr.filter(
        (el) =>
            (el.first_name && el.first_name.toLowerCase().startsWith(keyword.toLowerCase())) ||
            (el.second_name && el.second_name.toLowerCase().startsWith(keyword.toLowerCase())) ||
            (`${el.first_name} ${el.second_name}`.toLowerCase().includes(keyword.toLowerCase())) ||
            (`${el.second_name} ${el.first_name}`.toLowerCase().includes(keyword.toLowerCase()))
    );
    return result;
};
