import { useState, useEffect } from 'react';
import useSWRImmutable from 'swr/immutable';
import { API_HOST } from '../config';
import adminFetcher from './adminFetcher';

export default function usePriceLists() {
    const { data, error, mutate } = useSWRImmutable(
        `${API_HOST}/api/v1/manager-panel/shop/price-lists`,
        adminFetcher
    );

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (!error && data) {
            setIsLoading(false);
        }
    }, [data, error]);

    return {
        priceLists: data,
        isLoading,
        mutate,
    };
}
